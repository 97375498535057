<template>
  <div v-if="data.length > 1" class="menuBlock">
    <div
      class="menuBlock__scrollBlock"
      :style="`grid-template-columns: repeat(${data.length}, max-content)`"
    >
      <router-link
        v-for="el in data"
        :to="el.to"
        class="menuBlock__scrollBlock__link"
        @click="scrollToItem($event.target)"
      >
        <div
          class="menuBlock__scrollBlock__link__item"
          :class="examinationRoute(el) ? 'on' : 'off'"
        >
          <img :src="`/img/myMenuImg/${el.pathImg}.svg`" />
          <span>{{ el.name }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>
