<template>
    <div class="content">
        <input @keydown.esc="handleEsc" type="text" ref="someStuff">

        <div class="content__arrow">
            <span @click="back()">&#10094;</span>
        </div>

        <div class="content__imgBlock" :style="{ 'background-image': 'url(/presentation/' + (isGlava ? 'brics/' : '') + srcValue + (isGlava ? '.png' : '.webp') + ')' }"></div>

        <div class="content__arrow">
            <span @click="forward()">&#10095;</span>
        </div>
    </div>
</template>
<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>

<!-- убрать 5 слайд, убрать белые полосы,  -->
