export default {
  namespaced: true,

  actions: {
    async get(range) {
      try {
        const response = await fetch(`/cron/part7/buttons/'${{ range }}'.txt'`);
        const result = await response.json();
        if (!response.status.ok) {
          throw new Error('Ошибка при получении данных');
        }
        return result;
      } catch (e) {
        console.log('Ошибка', e);
      }
    },

    async getYear() {
      try {
        const response = await fetch(process.env.VUE_APP_DB + '365.json');
        const result = await response.json();
        if (!result) {
          throw new Error('Ошибка при получении данных');
        }
        return result;
      } catch (e) {
        console.log('Ошибка', e);
      }
    }
  }
}