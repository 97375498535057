<template>
  <footer class="footer" id="footer">
    <div class="footer__left">
      <img src="/img/esg.png" />
      <p>Проект соответствует <br />принципам <b>ESG</b></p>
    </div>
    <a class="footer__right" href="https://xn--b1acdfjbh2acclca1a.xn--p1ai/">
      <img src="/img/adm.svg" />
      <p><b>Администрация</b> <br />Нижнего Новгорода</p>
    </a>
  </footer>
</template>

<script setup>
</script>

<style lang="scss" scoped src="./style.scss"></style>
