<template>
  <component :is="layout + '-layout'" v-if="layout" />
  <div v-if="!internetConnection" class="internetConnectionMsg">
    <div class="internetConnectionMsg__container">
      <h2>Проверьте соединение и обновите страницу</h2>
    </div>
  </div>
  <!-- <a href="https://opros.acgnn.ru/execForm?form_uuid=741c685f-17d8-4891-842b-0633bc0e9184" class="bugLink">
    <svg width="46" height="46" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
      stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.344 17.719c1.354 1.102 2.156 2.16 2.156 4.031"></path>
      <path d="M6.656 17.719C5.302 18.82 4.5 19.878 4.5 21.75"></path>
      <path d="M18 9.75c1.354-1.102 1.5-2.628 1.5-4.5"></path>
      <path d="M6 9.656C4.646 8.554 4.5 7.122 4.5 5.25"></path>
      <path d="M21.75 13.508H18"></path>
      <path d="M6 13.508H2.25"></path>
      <path d="M12 9v12"></path>
      <path d="M12 21.003c-3.3 0-6-2.7-6-6V10.5C6 7.45 8.7 6 12 6s6 1.2 6 4.5v4.503c0 3.3-2.7 6-6 6Z"></path>
      <path
        d="M8.41 6.728a2.3 2.3 0 0 1-.16-.738 3.75 3.75 0 0 1 3.74-3.74h.02a3.75 3.75 0 0 1 3.74 3.74c-.01.23-.059.457-.146.67">
      </path>
    </svg>
  </a> -->
</template>

<style scoped lang="scss">

.internetConnectionMsg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .6);

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--acgWhite);

  z-index: 1000;
}

.bugLink {
  position: fixed;
  bottom: 13px;
  right: 25px;

  color: var(--acgTurquoise);
}

</style>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  components: {
    MainLayout, AuthLayout
  },

  computed: {
    internetConnection() {
      return this.$store.state.internetConnection;
    },
  },
}
</script>

<script setup>

import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const layout = computed(() => route.meta.layout);

</script>
