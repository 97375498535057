export default {
    name: "Multiselect",
    props: {
        placeholder: {
            type: String,
            default: "Выберете вариант"
        },

        value: {
            type: String,
            require: true,
        },

        answers: {
            type: Array,
            default: [],
        },

        isMulty: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            opened: false,
        }
    },

    computed: {
        showingAnswers() {
            if (!this.isMulty) return this.answers.find(el => el.value === this.value).text;
            let res = this.value.map(el => this.answers.find(ans => ans.value === el).text).join(", ");
            return res;
        },
    },

    methods: {
        toggleValue(value) {
            if (this.isMulty) {
                if (this.value.includes(value)) this.$emit("update:value", this.value.filter(el => el !== value));
                else this.$emit("update:value", [...this.value, value]);
            } else {
                this.$emit("update:value", value);
            }
        },

        toggleSelectAll() {
            if (this.value.length === this.answers.length) this.$emit("update:value", []);
            else this.$emit("update:value", this.answers.map(el => el.value));
        },

        toggleAnswers() {
            this.opened = !this.opened;
        },

        isSelected(el) {
            if (this.isMulty)
                return this.value.includes(el.value);
            return this.value === el.value;
        },
    },
}
