import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import alertPlagin from "@/plugins/alert.plugin";
import './css/style.scss';
import UIComponents from "@/components/general/UI/index.js"

const Vue = createApp(App);
Vue.config.silent = true;

Vue.use(alertPlagin);
Vue.use(store);
Vue.use(router);

UIComponents.forEach(component => {
    Vue.component(component.name, component)
})

Vue.mount('#app');

window.addEventListener('offline', (e) => {
    store.commit("setConnectionValue", false);
});

window.addEventListener('online', (e) => {
    store.commit("setConnectionValue", true);
    for (let chartName of Object.keys(store.state.chartLoading)) {
        if (!["chartMunicipalVehiclesMap", "chartMunicipalVehiclesMap"].includes(chartName)) {
            store.commit("loaded", chartName);
            store.commit("reload", chartName);
        }
    }
});
