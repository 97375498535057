function getCookie(name) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}
function userAlive(){
	let date = new Date(),
	data={
		'timeTo':Math.ceil(date.getTime()/1000),
		'url':window.location.href,
		'user_id':getCookie('newClientID')
	}
	sendUserAlive(data)
}
function sendUserAlive(data){
	// let	data = {name: 'Виктор',surname: 'Цой'},
	let boundary = String(Math.random()).slice(2),
			boundaryMiddle = '--' + boundary + '\r\n',
			boundaryLast = '--' + boundary + '--\r\n',
			body = ['\r\n']
	for (let key in data){body.push('Content-Disposition: form-data; name="' + key + '"\r\n\r\n' + data[key] + '\r\n')}
	body = body.join(boundaryMiddle) + boundaryLast;

	// Тело запроса готово, отправляем
	let xhr = new XMLHttpRequest();
	xhr.open('POST', 'https://api.acgnn.ru/v1/lobachevsky/userAlive.php', true);
	xhr.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + boundary);
	xhr.onreadystatechange = function(){
	  if (this.readyState != 4) return;
	  // alert( this.responseText );
	}
	xhr.send(body);
}
userAlive()
setInterval(userAlive,60000);
