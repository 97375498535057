export default {
    name: "myMenu",

    props: {
        data: undefined
    },

    methods: {
        examinationRoute(el) {
            if (this.$route.fullPath === el.to) {
                return true
            } else if (this.$route.fullPath.split('/').slice(0, 3).join('/') === el.to.split('/').slice(0, 3).join('/')) {
                return true
            } else if (this.$route.fullPath.split('?')[0] === el.to) {
                return true
            }
            return false
        },

        scrollToItem(el) {
            let containerOuterWidth = el.parentElement.parentElement.clientWidth;
            let itemOuterWidth = el.clientWidth;
            let itemOffsetLeft = el.offsetLeft
            let positionCetner = (containerOuterWidth / 2 - itemOuterWidth / 2);
            let scrollLeftUpd = itemOffsetLeft - positionCetner;

            el.parentElement.parentElement.scrollTo({
                left: scrollLeftUpd,
                behavior: "smooth",
            });
        }
    },
}
