function getCookie(name) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}
function handleClick(e){
	let date = new Date(),
	data={
		'timeTo':Math.ceil(date.getTime()/1000),
		'timeOn':Math.ceil(e.timeStamp/1000),
		'pointerType':e.pointerType,
		'url':e.target.baseURI,
		'html':e.target.outerHTML,
		'tag':e.target.tagName,
		'content':e.target.textContent,
		'user_id':getCookie('newClientID')
	}
	sendPostJS(data)
}
document.addEventListener('click', handleClick);

function sendPostJS(data){
	let boundary = String(Math.random()).slice(2),
		boundaryMiddle = '--' + boundary + '\r\n',
		boundaryLast = '--' + boundary + '--\r\n',
        body = ['\r\n']

	for (let key in data){body.push('Content-Disposition: form-data; name="' + key + '"\r\n\r\n' + data[key] + '\r\n')}
	body = body.join(boundaryMiddle) + boundaryLast;

	// Тело запроса готово, отправляем
	let xhr = new XMLHttpRequest();
	xhr.open('POST', 'https://api.acgnn.ru/v1/lobachevsky/catchLog.php', true);
	xhr.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + boundary);
	xhr.onreadystatechange = function(){
	  if (this.readyState != 4) return;
	  // alert( this.responseText );
	}
	xhr.send(body);
}
