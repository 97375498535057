import { createStore } from 'vuex';
import fines from "./modules/fines";
const store = createStore({
  state: {
    error: null,

    chartLoading: {},
    chartCorrect: {},
    chartData: {},

    isDeveloper: false,

    sufix: "",
    showDocBtn: false,
    headerEvent: "",

    homeTableView: false,
    showArchive: false,

    internetConnection: true,

    scrollPositions: {},

    groupsOpening: {},

    updateTime: "",

    newClientID: "",

    // карта
    map: undefined,
    layer: undefined,
    geoData: undefined,
    idFeature: undefined,
    feature: undefined,
    showPopup: true,
  },
  getters: {
    error: (s) => s.error,
    // карта
    getMap: (state) => state.map,
    getLayer: (state) => state.layer,
    getGeoData: (state) => state.geoData,
    getIdFeature: (state) => state.idFeature,
    getFeature: (state) => state.feature,
    getShowPopup: (state) => state.showPopup,
  },
  mutations: {
    // Блок мутаций стейтов ошибок
    changeError(state, error) {
      state.error = error;
    },
    resetError(state) {
      state.error = null;
    },

    // Блок мутаций, связанный с чартами (виджетами)
    loaded(state, chartName) {
      state.chartLoading[chartName] = false;
    },
    reload(state, chartName) {
      state.chartLoading[chartName] = true;
    },
    clearLoadingChartData(state) {
      state.chartLoading = {};
    },

    setChartData(state, chartData) {
      state.chartData[chartData.name] = chartData.data;
    },
    clearChartData(state, chartName) {
      state.chartData[chartName] = undefined;
    },

    correct(state, chartName) {
      state.chartCorrect[chartName] = true;
    },
    incorrect(state, chartName) {
      state.chartCorrect[chartName] = false;
    },

    // Мутация данных логина как разработчика
    signInDeveloper(state) {
      state.isDeveloper = true;
    },
    signOutDeveloper(state) {
      state.isDeveloper = false;
    },

    // Мутаци для дозаписи текста в хедере страниц
    setSufix(state, value) {
      state.sufix = value;
    },

    // Мутация состаяния вида главной страницы
    toggleHomeView(state) {
      state.homeTableView = !state.homeTableView;
    },

    // Мутация состаяния показывания архива
    toggleShowArchive(state) {
      state.showArchive = !state.showArchive;
    },
    setShowArchive(state, value) {
      state.showArchive = value;
    },

    // Мутация состояния подключения к интернету
    setConnectionValue(state, value) {
      state.internetConnection = value;
    },

    // Мутация состояния собития шапки
    setHeaderEvent(state, value) {
      state.headerEvent = value
    },

    // Мутация очистки собития шапки
    clearHeaderEvent(state) {
      state.headerEvent = ""
    },

    setShowDocBtn(state, value) {
      state.showDocBtn = value;
    },

    //Мутация добавления позиции скрола
    addScrollPosition(state, value) {
      state.scrollPositions[value.path] = value.position;
    },

    //Мутация открытия/закрытия группы
    openCloseGroups(state, [groupName, value]) {
      state.groupsOpening[groupName] = value;
    },

    //Мутация открытия/закрытия всех групп
    openAllCloseGroups(state, value) {
      for (let group in state.groupsOpening)
        state.groupsOpening[group] = value;
    },

    chartsReload(state, limitedChartsLength) {
      Object.keys(state.chartLoading).forEach(item => {
        state.chartLoading[item] = true;
      })
    },

    computeUpdateTime(state) {
      function setNecessaryZero(num) {
        if (num < 10) return "0" + num;
        return num;
      }

      let res = "";

      let intervals = [1, 16, 31, 46, 61];
      let time = new Date();

      for (let i in intervals) {
        if (time.getMinutes() < intervals[i]) {
          res = `${setNecessaryZero(time.getDate())}.${setNecessaryZero(time.getMonth() + 1)}.${time.getFullYear()}`;
          res += ` ${setNecessaryZero(time.getHours())}:${setNecessaryZero(intervals[i - 1]) ?? '46'}:00`;
          // res += ` ${setNecessaryZero(i === 0 ? time.getHours() - 1 : time.getHours())}:${setNecessaryZero(intervals[i - 1]) ?? '46'}:00`;
          break;
        }
      }

      state.updateTime = res;
    },

    setNewClientID(state, value) {
      state.newClientID = value;
    },


    // карта
    setMap(state, map) {
      state.map = map
    },
    setLayer(state, layer) {
      state.layer = layer
    },
    setGeoData(state, geoData) {
      state.geoData = geoData
    },
    setIdFeature(state, idFeature) {
      state.idFeature = idFeature
    },
    setFeature(state, feature) {
      state.feature = feature
    },
    setShowPopup(state, showPopup) {
      state.showPopup = showPopup
    },
  },
  actions: {
    toggleArchive(context, value = undefined) {
      if (value !== undefined) {
        context.commit("setShowArchive", value)
      } else {
        context.commit("toggleShowArchive");
      }

      if (context.state.showArchive) {
        context.commit("setSufix", "Архив");
      } else {
        context.commit("setSufix", "");
      }
    },
  },
  modules: {
    fines
  }
});

export default store;
