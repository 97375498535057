import router from "@/router/index.js"
import store from "@/store"

export default function scrollToScrollPositionInStore() {
    let currentRouteName = router.options.history.location;
    let element = document.body;
    if (element !== null && currentRouteName in store.state.scrollPositions) {
        setTimeout(async () => {
            // function scrollDelay(ms) {
            //     return new Promise(res => setTimeout(res, ms));
            // }

            // for (var y = 0; y <= store.state.scrollPositions[currentRouteName] + 260; y += 400) {
            //     await scrollDelay(20)
            // }
            // element.scrollTo({ top: store.state.scrollPositions[currentRouteName], behavior: 'smooth' })
            element.scrollTo({ top: store.state.scrollPositions[currentRouteName]})
        }
            , 100)
    }
}