<template>
    <div class="multiSelect" :class="{ 'opened': opened }">
        <div @click="toggleAnswers()" v-if="value.length === 0" class="multiSelect__placeholder">{{ placeholder }}</div>
        <div @click="toggleAnswers()" v-else :title="showingAnswers" class="multiSelect__answer">{{ showingAnswers.slice(0, 35)}}</div>

        <div @click="toggleAnswers()" class="multiSelect__arrowImg">
            <img src="/img/mayorsOrders/icons/arrow-right.svg" alt="" :class="{ 'opened': opened }">
        </div>

        <Transition>
            <div v-if="opened" class="multiSelect__options">
                <div v-if="isMulty" @click="toggleSelectAll" class="multiSelect__options__answer">
                    <div class="multiSelect__options__answer__checkboxBlock" :class="{ 'selected': value.length === answers.length }">
                        <img src="/img/mayorsOrders/icons/selected.svg" alt="">
                    </div>
                    <div class="multiSelect__options__answer__text">Выбрать все</div>
                </div>

                <div v-for="ans in answers" @click="toggleValue(ans.value)" :title="ans.text" class="multiSelect__options__answer">
                    <div class="multiSelect__options__answer__checkboxBlock" :class="{ 'selected': isSelected(ans), 'radio': !isMulty }">
                        <img src="/img/mayorsOrders/icons/selected.svg" alt="">
                    </div>
                    <div class="multiSelect__options__answer__text">{{ ans.text }}</div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>
