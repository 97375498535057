<template>
  <div class="wrapper">
    <the-header />
    <main>
      <router-view />
    </main>
    <the-footer />
  </div>
</template>

<script setup>

import TheFooter from "@/components/the/TheFooter/TheFooter.vue"
import TheHeader from "@/components/the/TheHeader/TheHeader.vue"
import { onBeforeMount } from "vue"
import router from "@/router/index.js"
import store from "@/store"
import scrollToScrollPositionInStore from "@/helpers/scrollToScrollPositionInStore"

router.beforeEach((to, from, next) => {
      document.title = to.meta.title || DEFAULT_TITLE;
      let element = document.body;
      if (element !== null) {
        store.commit("addScrollPosition", { path: from.fullPath, position: element.scrollTop });
      }
      next();
    })

    window.addEventListener('popstate', () => {
      scrollToScrollPositionInStore();
    })

</script>

<style scoped>
main {
  margin-top: 100px;

  overflow: hidden;
}
</style>
